import { Brand } from '@lbg-protection/lib-ui-monoceros'

export const brandHasGutter = (brand?: Brand) => {
  if (!brand) return undefined
  switch (brand) {
    case Brand.HALIFAX2020:
      return 3
    case Brand.ORION2024:
      return 2
    default:
      return undefined
  }
}

export const brandHasBackground = (brand?: Brand): boolean => {
  const hasBackground = brand && (brand === Brand.LLOYDS || brand === Brand.BOS)
  return !!hasBackground
}

export const isHalifax = (brand: Brand) => brand === Brand.HALIFAX2020

export const isLloyds = (brand: Brand) => brand === Brand.LLOYDS

export const isBOS = (brand: Brand) => brand === Brand.BOS

export const isSW = (brand: Brand) => brand === Brand.ORION2024

export const getIllustrationSize = (brand: Brand) => {
  return brand === Brand.LLOYDS ? '4.5rem' : '3.75rem'
}

export const getBrandNPSSurveyHref = (themeName: Brand): string => {
  switch (themeName) {
    case Brand.BOS:
      return 'https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_8cRkrioMi24gPhI?brand=BOS'
    case Brand.HALIFAX2020:
      return 'https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_8cRkrioMi24gPhI?brand=HAL'
    case Brand.LLOYDS:
      return 'https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_8cRkrioMi24gPhI?brand=NLB'
    case Brand.ORION2024:
      return 'https://lloydsbankinggroup.eu.qualtrics.com/jfe/form/SV_0Cen0X6uo7s9e6y'
    default:
      return ''
  }
}

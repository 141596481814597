import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Alert, Box, makeStyles, Typography, MonocerosTheme, Brand, useTheme } from '@lbg-protection/lib-ui-monoceros'
import { UnderwritingEnquiryDecision } from '@lbg-protection/api-client-nmp-journey'
import * as Types from '../../app/types'
import { useFeatureFlags } from '../../featureFlags'
import { selectCoverChoice, selectUserRoute } from '../../journeys/purchase/prePurchase.slice'
import { selectApplicantDetails } from '../../journeys/purchase/aboutYou.slice'
import { useJourneyChecker } from '../../journeyCwaConfig'
import { isBOS, isSW } from '../../utils/brandSpecificFormatting'
import { useContent } from '../Content/useContent'
import { SaveJourneyArgs } from '../SaveAndRetrieve/types'
import { LoadingSpinner } from '../Shared/LoadingSpinner/LoadingSpinner'
import ScreenTemplate from '../Shared/ScreenTemplate/ScreenTemplate'
import { StickyBasket } from '../Shared/StickyBasket/StickyBasket'
import { TechnicalError } from '../Shared/TechnicalError/TechnicalError'
import { VoucherIncentiveCard, VoucherDefaultData } from '../Shared/VoucherIncentive'
import { VoucherIncentiveContent } from '../Shared/VoucherIncentive/types'
import { VoucherIncentiveCardTiered, VoucherDefaultDataTiered } from '../Shared/VoucherIncentiveTiered'
import { VoucherIncentiveContentTiered } from '../Shared/VoucherIncentiveTiered/types'
import { useVoucherAmount } from '../Shared/VoucherIncentiveTiered/useVoucherAmount'
import { saveJourney } from '../SaveAndRetrieve/saveAndRetrieve.slice'
import { selectUwMeDecisions, selectUwMeEnquiryId } from '../UnderwriteMe/underwriteMe.slice'
import { isEligibleForProduct } from '../UnderwriteMe/utils/isEligible'
import {
  selectBodyCover as selectIndicativeBodyCover,
  selectLifeCover as selectIndicativeLifeCover,
} from '../IndicativeQuotePage/indicativeQuote.slice'
import { useAppSelector } from '../../app/hooks'
import { QuoteAccordions } from './QuoteAccordions'
import { QuotePageFooter } from './QuotePageFooter'
import { QuotePageContent } from './QuotePageContent'
import { Quotes } from './Quotes'
import {
  addToBasket,
  getPricingAnonymousQuote,
  removeFromBasket,
  selectBodyCover,
  selectBodyCoverPricingCallStatus,
  selectLifeCover,
  selectLifeCoverPricingCallStatus,
  selectNumberOfBodyCoverPricingCalls,
  selectNumberOfLifeCoverPricingCalls,
  selectValidationError,
  setValidationError,
  update,
} from './quotes.slice'
import {
  AddToBasketActionProps,
  PricingRequestParams,
  QuotePageProps,
  RemoveFromBasketActionProps,
  UpdateActionProps,
} from './types'
import { calculateSum, countCoversInBasket, isPricingCallPending, wasPricingCallRejected } from './utils'
import { useTagging } from '../../analytics/useTagging'
import { tagButtonClick } from '../../utils/tagUtils/tagsUtils'
import { ClinicInAPocketInfoSmall } from '../Shared/ClinicInAPocketInfoSmall/ClinicInAPocketInfoSmall'
import { ClinicInAPocketInfoMedium } from '../Shared/ClinicInAPocketInfoMedium/ClinicInAPocketInfoMedium'

const withStyles = makeStyles((theme: MonocerosTheme) => {
  const isSWBrand = isSW(theme.name as Brand)
  return {
    quotesWrapper: {
      paddingTop: isBOS(theme.name as Brand) ? '' : theme.spacing(5),
    },
    clinicWrapper: {
      paddingTop: theme.spacing(2),
      paddingLeft: isSWBrand ? '0.75rem' : '18px',
      paddingRight: isSWBrand ? '0.75rem' : '18px',
      background: isBOS(theme.name as Brand) ? theme.palette.secondary.light : 'transparent',
    },
    readInfo: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    readInfoWider: {
      paddingTop: theme.spacing(2),
      backgroundColor: theme.palette.secondary.light,
    },
    readInfoAlert: {
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
    clinicInAPocketMediumContainer: {
      marginTop: isBOS(theme.name as Brand) ? theme.spacing(3) : 'none',
      margin: isBOS(theme.name as Brand) ? 'none' : theme.spacing(3),
    },
  }
})

export const QuotePage = (props: QuotePageProps) => {
  const classes = withStyles({})
  const { lifeCover, bodyCover, lifeCoverPricingCallStatus, bodyCoverPricingCallStatus } = props
  const footerProps = { ...props, total: calculateSum(lifeCover, bodyCover) }

  const theme = useTheme<MonocerosTheme>()
  const { isFeatureEnabled } = useFeatureFlags()
  const { isJourneyUnAuth } = useJourneyChecker()
  const content = useContent<QuotePageContent>()

  const isIncentive = isFeatureEnabled('incentive')
  const incentiveContent: VoucherIncentiveContent = content('voucherIncentiveContent', VoucherDefaultData)
  const { giftCardValue, giftCardImage, policyDurationBullet, voucherDescription } = incentiveContent

  const isIncentiveTiered = isFeatureEnabled('incentiveTiered')
  const incentiveContentTiered: VoucherIncentiveContentTiered = content(
    'voucherIncentiveContentTiered',
    VoucherDefaultDataTiered,
  )

  const isClinicInAPocketContentEnabled = isFeatureEnabled('enableClinicInAPocketContent')
  const { giftCardImageTiered, voucherDescriptionTiered, policyDurationBulletTiered } = incentiveContentTiered
  const tieredVoucherAmount = useVoucherAmount()

  const isClinicInAPocketEnabled = isFeatureEnabled('enableClinicInAPocketContent')

  const indicativeLifeCover = useAppSelector((state) => selectIndicativeLifeCover(state.indicativeQuotes))
  const indicativeBodyCover = useAppSelector((state) => selectIndicativeBodyCover(state.indicativeQuotes))
  const { tagRoutePageLoad } = useTagging()
  const isIndicativeLifeCoverAdded = indicativeLifeCover.amount > 0 && indicativeLifeCover.term > 0
  const isIndicativeBodyCoverAdded = indicativeBodyCover.amount > 0 && indicativeBodyCover.term > 0
  const isIndicativeQuoteAdded = isIndicativeLifeCoverAdded || isIndicativeBodyCoverAdded

  useEffect(() => {
    tagRoutePageLoad(isIncentiveTiered && tieredVoucherAmount > 0 ? `${tieredVoucherAmount.toString()} value` : '')

    if (props.saveJourney && !isJourneyUnAuth) {
      props.saveJourney({
        isAutoSave: true,
      })
    }
  }, [])

  return (
    <ScreenTemplate
      dataTestId="quotePage"
      backgroundImagePath="url(./assets/backgroundImage.svg)"
      accordionGroup={<QuoteAccordions />}
      bottomNav={<QuotePageFooter {...footerProps} />}
      additionalInfo={
        <>
          {isIncentive && (
            <VoucherIncentiveCard
              policyDurationBullet={policyDurationBullet}
              voucherImageProps={giftCardImage}
              giftCardValue={giftCardValue}
              voucherDescription={voucherDescription}
            />
          )}
          {isIncentiveTiered && tieredVoucherAmount > 0 && (
            <VoucherIncentiveCardTiered
              policyDurationBullet={policyDurationBulletTiered}
              voucherImageProps={giftCardImageTiered}
              voucherDescription={voucherDescriptionTiered}
              voucherAmount={tieredVoucherAmount}
              onTsAndCsClick={() => tagButtonClick('View terms and conditions')}
            />
          )}
          {isClinicInAPocketContentEnabled && (
            <div className={classes.clinicInAPocketMediumContainer}>
              <ClinicInAPocketInfoMedium />
            </div>
          )}
        </>
      }
      showClinicInAPocketMediumInfo
    >
      {isPricingCallPending(lifeCoverPricingCallStatus, bodyCoverPricingCallStatus) && <LoadingSpinner />}
      {wasPricingCallRejected(lifeCoverPricingCallStatus, bodyCoverPricingCallStatus) && <TechnicalError />}
      <StickyBasket lifeCover={lifeCover} bodyCover={bodyCover} />
      <Box className={classes.quotesWrapper}>
        <Quotes {...props} hasIndicativeQuote={isIndicativeQuoteAdded} />
      </Box>
      {countCoversInBasket(lifeCover, bodyCover) > 0 && !isJourneyUnAuth && (
        <Box
          className={isBOS(theme.name as Brand) ? classes.readInfoWider : classes.readInfo}
          data-testid="read-download-full-quote-container"
        >
          <Alert
            severity="info"
            role="presentation"
            className={isBOS(theme.name as Brand) ? classes.readInfoAlert : ''}
          >
            <Typography data-testid="read-download-full-quote-message">
              Make sure you read and download your full quote. It contains more details about your policy and the total
              amount you&apos;ll pay until it ends.
            </Typography>
          </Alert>
        </Box>
      )}
      {isClinicInAPocketEnabled && (
        <Box className={classes.clinicWrapper}>
          <ClinicInAPocketInfoSmall dataTestId="cip-small-quote-page" />
        </Box>
      )}
    </ScreenTemplate>
  )
}

const mapDispatchToProps = (dispatch: Types.AppDispatch) => {
  return {
    callPricingAPI: (params: PricingRequestParams): void => dispatch(getPricingAnonymousQuote(params)),
    update: (params: UpdateActionProps): void => dispatch(update(params)),
    addToBasket: (params: AddToBasketActionProps): void => dispatch(addToBasket(params)),
    removeFromBasket: (params: RemoveFromBasketActionProps): void => dispatch(removeFromBasket(params)),
    setValidationError: (validationError: string): void => dispatch(setValidationError(validationError)),
    saveJourney: (args: SaveJourneyArgs) => dispatch(saveJourney(args)),
  }
}

const mapStateToProps = (state: Types.RootState) => ({
  numberOfLifeCoverPricingCalls: selectNumberOfLifeCoverPricingCalls(state.quotes),
  numberOfBodyCoverPricingCalls: selectNumberOfBodyCoverPricingCalls(state.quotes),
  dob: selectApplicantDetails(state.aboutYou).dateOfBirth,
  firstName: selectApplicantDetails(state.aboutYou).firstName,
  lastName: selectApplicantDetails(state.aboutYou).lastName,
  userRoute: selectUserRoute(state.prePurchase),
  prePurchaseCoverChoice: selectCoverChoice(state.prePurchase),
  lifeCoverPricingCallStatus: selectLifeCoverPricingCallStatus(state.quotes),
  bodyCoverPricingCallStatus: selectBodyCoverPricingCallStatus(state.quotes),
  lifeCover: selectLifeCover(state.quotes),
  bodyCover: selectBodyCover(state.quotes),
  validationError: selectValidationError(state.quotes),
  uwMeLifeOutcome: isEligibleForProduct(
    UnderwritingEnquiryDecision.ProductNameEnum.LIFE,
    selectUwMeDecisions(state.underwriteMe),
  ),
  uwMeCiOutcome: isEligibleForProduct(
    UnderwritingEnquiryDecision.ProductNameEnum.CI,
    selectUwMeDecisions(state.underwriteMe),
  ),
  enquiryId: selectUwMeEnquiryId(state.underwriteMe),
})

const ConnectedQuotePage = connect(mapStateToProps, mapDispatchToProps)(QuotePage)

export { ConnectedQuotePage }

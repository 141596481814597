import React from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { ButtonV2 as Button, Brand, useTheme, MonocerosTheme, makeStyles } from '@lbg-protection/lib-ui-monoceros'
import { PrePurchaseJourneyRouteEnum, prePurchaseSlice } from '../../../journeys/purchase'
import { isBOS, isLloyds } from '../../../utils/brandSpecificFormatting'
import { useJourneyNavMap } from '../../../journeyNavMap'
import { AppDispatch } from '../../../app/types'
import { useFeatureFlags } from '../../../featureFlags'
import { BottomNavigation } from '../../Shared/BottomNavigation'
import { tagChosenSumAssuredLed } from '../IndicativeQuoteCard/IndicativeQuoteCardContent/tags'
import { CoverSummaryInterface } from '../types'
import { tagButtonEvent } from '../tags'
import { PreviousButton } from './PreviousButton'
import { IndicativeQuotePageFooterProps } from './types'
import { ExitSurvey } from '../../Shared/ExitSurvey'
import { ClinicInAPocketInfoMedium } from '../../Shared/ClinicInAPocketInfoMedium/ClinicInAPocketInfoMedium'

export const IndicativeQuotePageFooter = (props: IndicativeQuotePageFooterProps) => {
  const currentTheme = useTheme<MonocerosTheme>()

  const withStyles = makeStyles((theme: MonocerosTheme) => {
    return {
      clinicInAPocketMediumContainer: {
        marginTop: isBOS(theme.name as Brand) ? theme.spacing(3) : 'none',
        margin: isBOS(theme.name as Brand) ? 'none' : theme.spacing(3),
      },
    }
  })

  const transparentBackground = !!isLloyds(currentTheme.name as Brand)
  const { isFeatureEnabled } = useFeatureFlags()
  const isClinicInAPocketContentEnabled = isFeatureEnabled('enableClinicInAPocketContent')

  const classes = withStyles()
  return (
    <>
      <BottomNavigation
        userDefinedEndActions={<EndActions {...props} tieredVoucherAmount={props.tieredVoucherAmount} />}
        userDefinedStartActions={<StartActions />}
        transparentBackground={transparentBackground}
      />
      {isClinicInAPocketContentEnabled && (
        <div className={classes.clinicInAPocketMediumContainer}>
          <ClinicInAPocketInfoMedium />
        </div>
      )}
      <ExitSurvey showSideMargin={false} />
    </>
  )
}

const tagChosenCover = (lifeCover: CoverSummaryInterface, bodyCover: CoverSummaryInterface, voucherAmount?: number) => {
  if (lifeCover?.amount > 0) {
    tagChosenSumAssuredLed(lifeCover, voucherAmount)
  }
  if (bodyCover.amount > 0) {
    tagChosenSumAssuredLed(bodyCover, voucherAmount)
  }
}

const EndActions = (props: IndicativeQuotePageFooterProps) => {
  const history = useHistory()
  const targetNav = useJourneyNavMap().getTargetNav('indicative-quote', 'about-you')
  const { isFeatureEnabled } = useFeatureFlags()
  const handleEndActions = (journeyRoute: PrePurchaseJourneyRouteEnum) => {
    props.setUserRoute(journeyRoute)
    history.push(journeyRoute === PrePurchaseJourneyRouteEnum.PathFinder ? 'about-you' : targetNav)
  }
  const handleButtonClick = (prePurchaseJourneyRoute: PrePurchaseJourneyRouteEnum) => {
    if (prePurchaseJourneyRoute === PrePurchaseJourneyRouteEnum.StraightToQuote) {
      const voucherAmountForTag = isFeatureEnabled('incentiveTiered') ? props.tieredVoucherAmount : undefined
      tagChosenCover(props.lifeCover, props.bodyCover, voucherAmountForTag)
      handleEndActions(PrePurchaseJourneyRouteEnum.StraightToQuote)
    } else {
      handleEndActions(PrePurchaseJourneyRouteEnum.PathFinder)
      tagButtonEvent('Help me work out what I need')
    }
  }

  return (
    <>
      {props.buttons.map((button) => (
        <Button
          variant={button.variant === 'outlined' ? 'outlined' : 'contained'}
          color="primary"
          data-testid={button['data-testid']}
          onClick={() => handleButtonClick(button.prePurchaseJourneyRoute as PrePurchaseJourneyRouteEnum)}
        >
          {button.name}
        </Button>
      ))}
    </>
  )
}

const StartActions = () => {
  return <PreviousButton />
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setUserRoute: (params: PrePurchaseJourneyRouteEnum): void => dispatch(prePurchaseSlice.actions.setUserRoute(params)),
})

const ConnectedIndicativeQuotePageFooter = connect(null, mapDispatchToProps)(IndicativeQuotePageFooter)

export { ConnectedIndicativeQuotePageFooter }

import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  makeStyles,
  MonocerosTheme,
  Typography,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'

const styles = makeStyles((theme: MonocerosTheme) => {
  return {
    clinicInAPocketTAndCText: {
      paddingBottom: theme.spacing(3),
    },
    clinicInAPocketContent: {
      paddingBottom: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
  }
})

const tAndCTitle = 'Clinic in a Pocket Terms & Conditions'
const tAndCContent =
  'Square Health offers Clinic in a Pocket GP services. Scottish Widows currently offers this service as an addition to the policy. It can be amended, replaced or withdrawn at any time.'

export const ClinicInAPocketTermsAndConditionsDialog = (props: Omit<DialogProps, 'children'>) => {
  const theme = useTheme<MonocerosTheme>()
  const classes = styles(theme)

  return (
    <Dialog {...props} data-testid="clinic-in-a-pocket-terms-and-conditions-dialog">
      <DialogTitle>{tAndCTitle}</DialogTitle>
      <DialogContent className={classes.clinicInAPocketContent}>
        <Typography className={classes.clinicInAPocketTAndCText}>{tAndCContent}</Typography>
        <div>
          <Divider />
          <Button variant="contained" color="primary" fullWidth onClick={() => props.onClose()}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../app/types'

export type SmokerDeclarationState = {
  smokerStatus: boolean | null
}

const initialState: SmokerDeclarationState = {
  smokerStatus: null,
}

export const smokerDeclarationSlice = createSlice({
  name: 'smokerDeclaration',
  initialState,
  reducers: {
    setSmokerStatus: (state: SmokerDeclarationState, action: PayloadAction<boolean>): SmokerDeclarationState => {
      state.smokerStatus = action.payload // eslint-disable-line no-param-reassign
      return state
    },
  },
})

export const { setSmokerStatus } = smokerDeclarationSlice.actions

const getState = (state: RootState): SmokerDeclarationState => state.smokerDeclaration

export const selectSmokerStatus = createSelector(getState, (state) => state.smokerStatus)

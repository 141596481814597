import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { Box, makeStyles, useTheme, MonocerosTheme, Brand } from '@lbg-protection/lib-ui-monoceros'
import * as Types from '../../app/types'
import { useFeatureFlags } from '../../featureFlags'
import { useJourneyChecker } from '../../journeyCwaConfig'
import { selectApplicantDetails } from '../../journeys/purchase/aboutYou.slice'
import { isBOS, isSW } from '../../utils/brandSpecificFormatting'
import { useContent } from '../Content/useContent'
import { LoadingSpinner } from '../Shared/LoadingSpinner/LoadingSpinner'
import { VoucherIncentiveContent } from '../Shared/VoucherIncentive/types'
import ScreenTemplate from '../Shared/ScreenTemplate/ScreenTemplate'
import { TechnicalError } from '../Shared/TechnicalError/TechnicalError'
import { PageHeader } from '../Shared/PageHeader'
import { VoucherIncentiveContentTiered } from '../Shared/VoucherIncentiveTiered/types'
import { VoucherIncentiveCard, VoucherDefaultData } from '../Shared/VoucherIncentive'
import { VoucherIncentiveCardTiered, VoucherDefaultDataTiered } from '../Shared/VoucherIncentiveTiered'
import { selectSmokerStatus } from '../SmokerDeclarationPage/SmokerDeclaration/smokerDeclaration.slice'
import { update as updateQuote } from '../QuotePage/quotes.slice'
import { useVoucherAmount } from '../Shared/VoucherIncentiveTiered/useVoucherAmount'
import { ConnectedIndicativeQuotePageFooter } from './IndicativeQuotePageFooter'
import { IndicativeQuote } from './IndicativeQuote'
import {
  getPricingAnonymousIndicativeQuote,
  selectBodyCover,
  selectBodyCoverPricingCallStatus,
  selectLifeCover,
  selectLifeCoverPricingCallStatus,
  selectNumberOfBodyCoverPricingCalls,
  selectNumberOfLifeCoverPricingCalls,
  selectValidationError,
  setValidationError,
  update as updateIndicativeQuote,
} from './indicativeQuote.slice'
import { BasePricingRequestParams, IndicativeQuotePageProps, UpdateActionProps } from './types'
import { IndicativeQuotePageContent } from './IndicativeQuotePageContent'
import { calculateSum, isPricingCallPending, wasPricingCallRejected } from './utils'
import { useTagging } from '../../analytics/useTagging'

const withStyles = makeStyles((theme) => {
  const { name = Brand.HALIFAX2020 } = useTheme<MonocerosTheme>()
  const isSWBrand = isSW(name)
  return {
    quotesWrapper: {
      paddingTop: isBOS(name) || isSWBrand ? undefined : theme.spacing(5),
    },
    readInfo: {
      marginTop: theme.spacing(4),
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
    },
  }
})

export const IndicativeQuotePage = (props: IndicativeQuotePageProps) => {
  const { lifeCover, bodyCover, lifeCoverPricingCallStatus, bodyCoverPricingCallStatus, callPricingAPI, dob } = props
  const { tagRoutePageLoad } = useTagging()
  const theme = useTheme<MonocerosTheme>()
  const classes = withStyles({})
  const { isJourneyUnAuth } = useJourneyChecker()
  const footerProps = { ...props, total: calculateSum(lifeCover, bodyCover) }
  const { isFeatureEnabled } = useFeatureFlags()
  const isIncentive = isFeatureEnabled('incentive')
  const isIncentiveTiered = isFeatureEnabled('incentiveTiered')
  const content = useContent<IndicativeQuotePageContent>()
  const isBOSTheme = isBOS(theme.name as Brand)
  const isSWTheme = isSW(theme.name as Brand)
  useEffect(() => {
    tagRoutePageLoad()
  }, [])

  const buttons = content('buttons', [
    {
      name: 'Continue',
      variant: 'contained',
      prePurchaseJourneyRoute: 'StraightToQuote',
      'data-testid': 'indicative-personalised-quote-button',
    },
  ])

  const incentiveContent: VoucherIncentiveContent = content('voucherIncentiveContent', VoucherDefaultData)
  const { giftCardValue, giftCardImage, policyDurationBullet, voucherDescription } = incentiveContent

  const incentiveContentTiered: VoucherIncentiveContentTiered = content(
    'voucherIncentiveContentTiered',
    VoucherDefaultDataTiered,
  )
  const { giftCardImageTiered, voucherDescriptionTiered, policyDurationBulletTiered } = incentiveContentTiered
  const hasDOB = dayjs(dob).isValid()

  const callPricingAPIWithJourneyInfo = (params: BasePricingRequestParams) => {
    if (callPricingAPI) {
      callPricingAPI({ ...params, isJourneyUnAuth })
    }
  }

  const tieredVoucherAmount = useVoucherAmount('indicative')

  return (
    <>
      <ScreenTemplate
        dataTestId="indicativeQuotePage"
        backgroundImagePath="url(./assets/backgroundImage.svg)"
        bottomNav={
          <ConnectedIndicativeQuotePageFooter
            {...footerProps}
            buttons={Object.values(buttons)}
            tieredVoucherAmount={tieredVoucherAmount}
          />
        }
        pageHeader={
          isBOSTheme || isSWTheme ? (
            <PageHeader headerText="Build your quick quote" dataTestId="product-info-page-header" />
          ) : (
            undefined
          )
        }
        additionalInfo={
          <>
            {isIncentive && (
              <VoucherIncentiveCard
                policyDurationBullet={policyDurationBullet}
                voucherImageProps={giftCardImage}
                giftCardValue={giftCardValue}
                withPadding={!isBOS(theme.name as Brand)}
                voucherDescription={voucherDescription}
              />
            )}
            {isIncentiveTiered && tieredVoucherAmount > 0 && (
              <VoucherIncentiveCardTiered
                policyDurationBullet={policyDurationBulletTiered}
                voucherImageProps={giftCardImageTiered}
                withPadding={!isBOS(theme.name as Brand)}
                voucherDescription={voucherDescriptionTiered}
                voucherAmount={tieredVoucherAmount}
              />
            )}
          </>
        }
        showClinicInAPocketMediumInfo
      >
        {isPricingCallPending(lifeCoverPricingCallStatus, bodyCoverPricingCallStatus) && <LoadingSpinner />}
        {(wasPricingCallRejected(lifeCoverPricingCallStatus, bodyCoverPricingCallStatus) || !hasDOB) && (
          <TechnicalError />
        )}
        <Box className={classes.quotesWrapper}>
          <IndicativeQuote {...props} callPricingAPI={callPricingAPIWithJourneyInfo} />
        </Box>
      </ScreenTemplate>
    </>
  )
}

const mapDispatchToProps = (dispatch: Types.AppDispatch) => {
  return {
    callPricingAPI: (params: BasePricingRequestParams): void => dispatch(getPricingAnonymousIndicativeQuote(params)),
    update: (params: UpdateActionProps): void => {
      dispatch(updateIndicativeQuote(params))
      if (Number(params.amount) > 0 && Number(params.term) > 0) {
        dispatch(updateQuote(params))
      }
    },
    setValidationError: (validationError: string): void => dispatch(setValidationError(validationError)),
  }
}

const mapStateToProps = (state: Types.RootState) => ({
  numberOfLifeCoverPricingCalls: selectNumberOfLifeCoverPricingCalls(state.indicativeQuotes),
  numberOfBodyCoverPricingCalls: selectNumberOfBodyCoverPricingCalls(state.indicativeQuotes),
  smokerStatus: selectSmokerStatus(state),
  dob: selectApplicantDetails(state.aboutYou).dateOfBirth,
  firstName: selectApplicantDetails(state.aboutYou).firstName,
  lastName: selectApplicantDetails(state.aboutYou).lastName,
  lifeCoverPricingCallStatus: selectLifeCoverPricingCallStatus(state.indicativeQuotes),
  bodyCoverPricingCallStatus: selectBodyCoverPricingCallStatus(state.indicativeQuotes),
  lifeCover: selectLifeCover(state.indicativeQuotes),
  bodyCover: selectBodyCover(state.indicativeQuotes),
  validationError: selectValidationError(state.indicativeQuotes),
})

const ConnectedIndicativeQuotePage = connect(mapStateToProps, mapDispatchToProps)(IndicativeQuotePage)

export { ConnectedIndicativeQuotePage }

import { v4 as uuidGen } from 'uuid'
import {
  AnswerUnderwritingQuestionRequestBody,
  AnswerUnderwritingQuestionRequestPathParams,
  CloseUnderwritingEnquiryRequestPathParams,
  CreatePolicyApplicationRequestBody,
  OptionListResponseBodyData,
  PricingQuickQuoteRequestBody,
  PricingRequestBody,
  PricingResponseBodyData,
  RetrieveUnderwritingQuestionOptionListRequestPathParams,
  RetrieveUnderwritingQuestionOptionListRequestQuery,
  ServiceClient as JourneyClient,
  StartUnderwritingEnquiryRequestBody,
  StartUnderwritingEnquiryRequestQuery,
  UnderwritingEnquiryResponseBodyData,
  PafLookupClientDataInterface,
  PafLookupResponseData,
} from '@lbg-protection/api-client-public-site-journey'
import { config, JourneyApiConfigInterface } from '../config'
import { onErrorThirdPartyLogger } from '../errorReporter/ErrorReporter/ErrorReporter'

let apiClient: JourneyClient & { isMimickingApiConnect?: boolean }

const getJourneyServiceConfig = async (): Promise<JourneyApiConfigInterface> => {
  const response = await config.get()
  return response.journeyApi
}

const lazyInstantiate = async (): Promise<void> => {
  if (!apiClient) {
    const journeyServiceConfig = await getJourneyServiceConfig()
    const isMimicAPIC = `${journeyServiceConfig?.mimicAPIC}` === `true`
    let host: string
    let port: number
    let prefix: string
    if (isMimicAPIC) {
      host = `${journeyServiceConfig.protocol}://${journeyServiceConfig.mimicHost}`
      port = Number(journeyServiceConfig.mimicPort)
      prefix = journeyServiceConfig.mimicPrefix
    } else {
      host = `${journeyServiceConfig.protocol}://${journeyServiceConfig.host}`
      port = Number(journeyServiceConfig.port)
      prefix = journeyServiceConfig.prefix
    }

    apiClient = new JourneyClient({
      cascadeHeaders: Boolean(journeyServiceConfig.cascadeHeaders),
      host,
      port,
      prefix,
      maxRetries: journeyServiceConfig.retryConfig.maxRetries,
      retryDelayMs: journeyServiceConfig.retryConfig.retryDelayMs,
      authConfig: journeyServiceConfig.authConfig,
      ignoreResponseValidation: true,
    })
    apiClient.isMimickingApiConnect = isMimicAPIC
  }
}

const getDefaultHeaders = (
  mockServiceScenariokey?: string,
  mockServiceScenarioName?: string,
): Record<string, string> => {
  const isDevEnv = process.env.NODE_ENV !== 'production'
  const headers: Record<string, string> = {
    'X-Correlation-ID': uuidGen(),
    ...(isDevEnv &&
      mockServiceScenarioName &&
      mockServiceScenariokey && {
        'mock-test-scenario': JSON.stringify({ [mockServiceScenariokey]: mockServiceScenarioName }),
      }),
  }
  return headers
}

const createPolicyApplication = async (data: CreatePolicyApplicationRequestBody): Promise<void> => {
  try {
    await lazyInstantiate()

    await apiClient.createPolicyApplication({
      body: data,
      headers: getDefaultHeaders(),
    })
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getPricingQuickQuote = async (body: PricingQuickQuoteRequestBody): Promise<PricingResponseBodyData> => {
  try {
    await lazyInstantiate()
    const headers = getDefaultHeaders()
    const pricingQuote = await apiClient.getPricingQuickQuote({ body, headers })

    return pricingQuote.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getPostalAddress = async (body: PafLookupClientDataInterface): Promise<PafLookupResponseData> => {
  try {
    await lazyInstantiate()

    const postalAddressResponse = await apiClient.pafLookup(body)

    return postalAddressResponse.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getPricingAnonymousQuote = async (body: PricingRequestBody): Promise<PricingResponseBodyData> => {
  try {
    await lazyInstantiate()

    const pricingQuote = await apiClient.getPricingAnonymousQuote({ body, headers: getDefaultHeaders() })

    return pricingQuote.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const uwMeStartEnquiry = async (
  body: StartUnderwritingEnquiryRequestBody,
  query: StartUnderwritingEnquiryRequestQuery,
): Promise<UnderwritingEnquiryResponseBodyData> => {
  try {
    await lazyInstantiate()

    const startEnquiryResponse = await apiClient.startUnderwritingEnquiry({
      body,
      query,
      headers: getDefaultHeaders(),
    })

    return startEnquiryResponse.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const uwMeAnswerEnquiryQuestion = async (
  body: AnswerUnderwritingQuestionRequestBody,
  path: AnswerUnderwritingQuestionRequestPathParams,
): Promise<UnderwritingEnquiryResponseBodyData> => {
  try {
    await lazyInstantiate()

    const answerQuestionResponse = await apiClient.answerUnderwritingQuestion({
      body,
      path,
      headers: getDefaultHeaders(),
    })

    return answerQuestionResponse.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const uwMeCloseEnquiry = async (
  path: CloseUnderwritingEnquiryRequestPathParams,
): Promise<UnderwritingEnquiryResponseBodyData> => {
  try {
    await lazyInstantiate()

    const closeEnquiryResponse = await apiClient.closeUnderwritingEnquiry({
      path,
      headers: getDefaultHeaders(),
    })

    return closeEnquiryResponse.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

const getOptionBackedQuestionOptions = async (
  path: RetrieveUnderwritingQuestionOptionListRequestPathParams,
  query: RetrieveUnderwritingQuestionOptionListRequestQuery,
): Promise<OptionListResponseBodyData> => {
  try {
    await lazyInstantiate()

    const resp = await apiClient.retrieveUnderwritingQuestionOptionList({ path, query, headers: getDefaultHeaders() })
    return resp.data
  } catch (error) {
    onErrorThirdPartyLogger(error)
    throw error
  }
}

export const unAuthJourneyApi = () => {
  return {
    createPolicyApplication,
    getPricingQuickQuote,
    getPricingAnonymousQuote,
    uwMeAnswerEnquiryQuestion,
    uwMeCloseEnquiry,
    uwMeStartEnquiry,
    getOptionBackedQuestionOptions,
    getPostalAddress,
  }
}

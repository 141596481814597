import {
  Typography,
  makeStyles,
  Box,
  Icon,
  MonocerosTheme,
  Brand,
  Container,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import React from 'react'
import { isLloyds } from '../../../utils/brandSpecificFormatting'

interface ClinicInAPocketInfoSmallProps {
  dataTestId: string
}

const useStyles = makeStyles((theme: MonocerosTheme) => ({
  clinicInAPocketBox: {
    backgroundColor: theme.name === Brand.BOS ? theme.palette.namedColours?.bosBabyBlue : theme.palette.secondary.light,
    borderRadius: theme.name === Brand.HALIFAX2020 ? theme.spacing(1) : 0,
    display: 'flex',
    alignItems: 'center',
    padding: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },

  clinicInAPocketIcon: {
    paddingRight: theme.spacing(3),
  },
}))

export const ClinicInAPocketInfoSmall = ({ dataTestId }: ClinicInAPocketInfoSmallProps) => {
  const classes = useStyles()
  const theme = useTheme<MonocerosTheme>()
  const isLLoydsBrand = isLloyds(theme.name as Brand)
  return (
    <Box component={Container} data-testid={dataTestId} className={classes.clinicInAPocketBox}>
      <Icon
        size={isLLoydsBrand ? '4.5rem' : '5rem'}
        name="clinic-in-a-pocket-illustration"
        htmlColor={isLLoydsBrand ? theme.palette.namedColours?.lloydsBrightGreen : 'primary'}
        color={!isLLoydsBrand ? 'primary' : undefined}
        className={classes.clinicInAPocketIcon}
      />
      <Typography variant="body1">You will also receive Clinic in a Pocket free of charge.</Typography>
    </Box>
  )
}

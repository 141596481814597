import React, { useState } from 'react'
import {
  Button,
  Card,
  makeStyles,
  Icon,
  MonocerosTheme,
  Typography,
  Brand,
  useTheme,
} from '@lbg-protection/lib-ui-monoceros'
import { ClinicInAPocketTermsAndConditionsDialog } from '../ClinicInAPocketTermsAndConditionsDialog/ClinicInAPocketTermsAndConditionsDialog'

const clinicInAPocketInfoMediumTitle = 'Clinic in a Pocket from our partner Square Health'
const paragraphOne =
  'Get the Clinic in a Pocket service for free when you buy this cover. Book a phone call with the 24/7 virtual GP service anywhere in the world, no matter the time.'
const paragraphTwo = 'You can also have a repeat prescription delivered to your door for free (UK only).'
const paragraphThree =
  'Scottish Widows currently offers this service as an addition to the policy. It can be amended, replaced or withdrawn at any time.'

const styles = makeStyles((theme: MonocerosTheme) => {
  return {
    lloydsClinicInAPocketTitle: {
      color: theme.palette.primary.main,
    },
    clinicInAPocketMediumCard: {
      backgroundColor: theme.palette.secondary.light,
    },
    clinicInAPocketMediumIcon: {
      paddingBottom: theme.spacing(1),
      color: theme.name === Brand.LLOYDS ? theme.palette.namedColours?.lloydsBrightGreen : theme.palette.primary.main,
    },
  }
})

export const ClinicInAPocketInfoMedium = () => {
  const [ciapTAndCOpen, setCiapTAndCOpen] = useState(false)
  const classes = styles()
  const theme: MonocerosTheme = useTheme()
  return (
    <Card className={classes.clinicInAPocketMediumCard} data-testid="clinic-in-a-pocket-info-medium">
      <Icon
        name="clinic-in-a-pocket-illustration"
        size="4rem"
        className={classes.clinicInAPocketMediumIcon}
        color="primary"
      />
      <Typography
        variant="h4"
        className={theme.name === Brand.LLOYDS ? classes.lloydsClinicInAPocketTitle : ''}
        gutterBottom
      >
        {clinicInAPocketInfoMediumTitle}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {paragraphOne}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {paragraphTwo}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {paragraphThree}
      </Typography>
      <Button
        variant="outlined"
        data-testid="view-terms-and-conditions-clinic"
        fullWidth
        onClick={() => setCiapTAndCOpen(true)}
        color="primary"
      >
        Terms & conditions
      </Button>
      <ClinicInAPocketTermsAndConditionsDialog open={ciapTAndCOpen} onClose={() => setCiapTAndCOpen(false)} />
    </Card>
  )
}
